<template>
  <van-tabbar route active-color="#23b593">
    <van-tabbar-item icon="apps-o" to="/jcw/">首页</van-tabbar-item>
    <van-tabbar-item icon="bullhorn-o" to="/jcw/alarm">告警</van-tabbar-item>
    <van-tabbar-item icon="orders-o" to="/jcw/history">历史</van-tabbar-item>
    <van-tabbar-item icon="setting-o" to="/jcw/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabbar',
  data(){
    return{
    }
  },
  mounted(){
  },
  methods:{
  }
}
</script>
